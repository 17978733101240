// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Grid,
  Link,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuthState } from 'context/AuthContext';
import { logOut } from 'services/auth';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ExpandMore } from '@mui/icons-material';
import { blackout, doble, sunscreen } from 'assets/cortinas-a-medida';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from 'react-redux';
import { CustomCurtainsService } from 'services';

const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1, '10rem'),
  margin: 0,
  height: 92,
  backgroundColor: '#E5E5E5',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 1),
  },
}));

const Menu = styled(MuiMenu)(() => ({
  '& .MuiPaper-root': {
    backgroundColor: 'white', //'#E5E5E5',
    minWidth: 200,
    '& .MuiMenu-list': {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: 14,
      color: '#5B5B5B',
    },
  },
}));

const MenuItem = styled(MuiMenuItem)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: 14,
  color: '#5B5B5B',
}));

const DecoHome = styled(Link)(({ path }) => ({
  fontFamily: 'Rubik, sans-serif',
  fontWeight: path ? 600 : 400,
  fontSize: 17,
  color: '#914DC7',
  textDecoration: 'none',
  '&:hover': {
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const MenuLink = styled(Link)(({ path }) => ({
  fontFamily: 'Rubik, sans-serif', 
  fontWeight: path ? 600 : 400,
  fontSize: 17,
  color: '#185D22',
  textDecoration: 'none',
  '&:hover': {
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [customCurtains, setCustomCurtains] = useState([]);
  const openMenu1 = Boolean(anchorEl?.id === 'menu-1');
  const openMenu2 = Boolean(anchorEl?.id === 'menu-2');
  const userMenu = Boolean(anchorEl?.id === 'user-menu');

  const pedido = useSelector((state) => state.pedido);
  useEffect(() => {
    CustomCurtainsService.getAll().then((curtains) =>
      setCustomCurtains(curtains),
    );
  }, []);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirect = (url) => {
    setAnchorEl(null);
    navigate(url);
  };

  const returnCantidadCarrito = () => {
    return Number(pedido.length);
  };
  return (
    <Root item container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Logo onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
      </Grid>
      <Grid item>
        <MenuLink id="menu-1" onClick={handleClick}>
          Cortinas a medida
        </MenuLink>
        <Menu anchorEl={anchorEl} open={openMenu1} onClose={handleClose}>
          {customCurtains.map(({ id, name, slug, images }) => (
            <MenuItem
              key={id}
              onClick={() => redirect(`/quoter?category=${slug}`)}
            >
              <img
                src={images[0]}
                height={100}
                width={100}
                style={{ marginRight: 5 }}
              />
              Cortina a medida - {name}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item>
        <MenuLink id="menu-2" onClick={() => redirect('/standar')}>
          {/* onClick={handleClick}> */}
          Cortinas estandar
        </MenuLink>
        {/* <Menu anchorEl={anchorEl} open={openMenu2} onClose={handleClose}>
          <Accordion elevation={0} disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: '#185D22' }} />}
            >
              <Typography color={'#185D22'}>Interior</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <MenuItem onClick={() => redirect('/standar')}>Blackout</MenuItem>
              <MenuItem onClick={() => redirect('/standar')}>
                Sunscreen
              </MenuItem>
              <MenuItem onClick={() => redirect('/standar')}>Zebra</MenuItem>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ color: 'white' }} variant="middle" />
        </Menu> */}
      </Grid>
      <Grid item>
        <MenuLink onClick={() => navigate('/catalogo')}>
          Catálogo textil
        </MenuLink>
      </Grid>
      <Grid item>
        <DecoHome onClick={() => navigate('/decohome')}>Deco Home</DecoHome>
      </Grid>
      <Grid item>
        <MenuLink onClick={() => navigate('/distribuidores')}>
          Distribuidores
        </MenuLink>
      </Grid>
      <Grid item>
        <MenuLink onClick={() => navigate('/contact')}>Contacto</MenuLink>
      </Grid>
      <Grid item>
        <MenuLink
          path={'/contact' === location.pathname}
          onClick={() => navigate('/cart')}
        >
          <Badge badgeContent={returnCantidadCarrito()} color={'primary'}>
            <ShoppingCartIcon sx={{ fontSize: 30 }} />
          </Badge>
        </MenuLink>
      </Grid>
      {/* <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Button id="user-menu" onClick={handleClick}>
          <Avatar />
        </Button>
        {user ? (
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={userMenu}
            onClose={handleClose}
          >
            <Typography
              sx={{
                padding: '8px 16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                fontSize: 14,
                color: '#5B5B5B',
              }}
            >
              {user.name || user.email}
            </Typography>
            <Divider variant="middle" />
            <MenuItem onClick={() => navigate('/user')}>
              Datos del usuario
            </MenuItem>
            {user.rol === 'admin' && (
              <MenuItem onClick={() => navigate('/products')}>
                Backoffice
              </MenuItem>
            )}
            <MenuItem onClick={logOut}>Cerrar sesión</MenuItem>
          </Menu>
        ) : (
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={userMenu}
            onClose={handleClose}
          >
            <MenuItem onClick={() => navigate('/login')}>
              Iniciar Sesión
            </MenuItem>
          </Menu>
        )}
      </Grid> */}
    </Root>
  );
};

export default Header;
