import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography, Button } from '@mui/material';

import 'swiper/css/autoplay';

//Imagenes
import catalogueImg1 from './assets/catalogueImg1.jpg';
import imgCatalogo from './assets/imgCatalogo.png';
import imgExterior from './assets/imgExterior.png';
import imgInterior from './assets/imgInterior.png';

//Imagenes
import img1 from '../Home/assets/img1.jpg';
import img2 from '../Home/assets/img2.jpg';
import img7 from '../Home/assets/img7.jpg';

import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';
import { CatalogueFilters } from '../../components/CatalogueFilters';

import CardItem from '../../components/CardProduct/index';

import { CategoryCatalogueService } from 'services';
import { getCollectionCatalogueProducts } from '../../services/products';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 400,
  textAlign: props.align || 'center',
  color: props.color || 'black',
  textTransform: 'none',
}));

const TextBtn = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Rubik',
  fontWeight: props.Weight || 600,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
  textIndent: '5px',
  fontSize: '14px',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#185D22',
  borderRadius: '9px',
  color: 'white',
  fontSize: '12px',
  width: '12rem',
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const Catalogue = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [viewport_width, setViewport_width] = useState([]);
  const [showCatalogo, setShowCatalogo] = useState(false);

  const [products, setProducts] = useState([]);
  const [filterCatalogue, setFilterCatalogue] = useState([]);
  const [IntText, setIntExt] = useState('Interior');
  const [NameSelected, setNameSelected] = useState('Blackout');
  const [categoriesCatalogue, setCategoriesCatalogue] = useState([]);
  const [categoriesCatalogueInt, setCategoriesCatalogueInt] = useState([]);
  const [categoriesCatalogueExt, setCategoriesCatalogueExt] = useState([]);

  const [filterCategorieFromDialog, setFilterCategorieFromDialog] =
    useState('');

  window.addEventListener('resize', function () {
    setViewport_width(window.innerWidth);
  });

  useEffect(() => {
    setIsLoading(true);
    getProducts();
    getAllCategoriesCatalogue();
    getAllCategoriesCatalogueInt();
    getAllCategoriesCatalogueExt();
    setIsLoading(false);
  }, []);

  const getProducts = async () => {
    try {
      const result = await getCollectionCatalogueProducts();
      console.log('result1: ', result);
      result.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      console.log('result2: ', result);
      setProducts(result);
    } catch (error) {
      setProducts([]);
      // alert('Fallo al subir el archivo');
    }
  };

  const getAllCategoriesCatalogue = async () => {
    const categories = await CategoryCatalogueService.getAll();
    setCategoriesCatalogue(categories);
  };

  const getAllCategoriesCatalogueExt = async () => {
    const categories = await CategoryCatalogueService.getAllExt();
    setCategoriesCatalogueExt(categories);
  };

  const getAllCategoriesCatalogueInt = async () => {
    const categories = await CategoryCatalogueService.getAllInt();
    setCategoriesCatalogueInt(categories);
  };

  return (
    <>
      <Grid item xs={12}>
        <img
          className="img"
          width={'100%'}
          height={'600px'}
          src={imgCatalogo}
          alt=""
        />
      </Grid>
      {showCatalogo == true ? (
        <Grid container direction="row" mt={5} mb={10} ml={1}>
          <LoadingCircularProgress open={isLoading} />
          {/* /FILTROS/ */}
          <Grid item lg={2} xs={12}>
            <CatalogueFilters
              catalogue={products}
              IntExt={setIntExt}
              NameSelected={setNameSelected}
              categoriesCatalogue={categoriesCatalogue}
              categoriesCatalogueInt={categoriesCatalogueInt}
              categoriesCatalogueExt={categoriesCatalogueExt}
              setFilterCatalogue={setFilterCatalogue}
              filterCategorieFromDialog={filterCategorieFromDialog}
            />
          </Grid>
          {/* /FILTROS/ */}

          {/* /PRODUCTOS/ */}
          <Grid
            container
            item
            lg={10}
            xs={12}
            pl={'20px'}
            direction={'row'}
            spacing={2}
          >
            {filterCatalogue.map((element, index) => {
              return (
                <Grid item key={index}>
                  <CardItem
                    item={element}
                    isEdit={false}
                    //setItemForEdit={(e) => handleSetItemForEdit(e)}
                    //setItemForDelete={(e) => handleSetItemForDelete(e)}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* /PRODUCTOS/ */}
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid item marginBottom={'20px'}>
            <Text mt={10} mb={10} marginLeft={'20%'} marginRight={'20%'}>
              Contamos con un amplio catálogo de telas de origen internacional y
              de marcas de primera linea, que garantizan la excelencia de
              nuestros productos y un diseño completamente a la vanguardia.
            </Text>
          </Grid>
          <Grid item container display={'flex'} flexDirection={'row'} mb={5}>
            <Grid
              item
              container
              xs={12}
              display={'flex'}
              justifyContent={'space-around'}
            >
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <img width={'75%'} height={'100%'} src={imgInterior} />
                <Btn>
                  <TextBtn Weight={300} onClick={() => setShowCatalogo(true)}>
                    {'Interior '}
                  </TextBtn>{' '}
                  {/* <TextBtn>{' A MEDIDA'}</TextBtn> */}
                </Btn>
              </Grid>
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <img width={'75%'} height={'100%'} src={imgExterior}></img>
                <Btn>
                  <TextBtn Weight={300} onClick={() => setShowCatalogo(true)}>
                    {'Exterior'}
                  </TextBtn>
                  {/* <TextBtn>{'STANDAR'}</TextBtn> */}
                </Btn>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            width={'100%'}
            height={'300px'}
            xs ={123}
            style={{
              backgroundImage: `url(${img7})`,
              backgroundRepeat: 'no-repeat',
              // width: '100%',
              // height: '300px',
            }}
            display={'flex'}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}
            marginTop={'20px'}
          >
            <Grid item marginBottom={'2%'} marginRight={'5%'}>
              <Btn>
                <TextBtn Weight={700}>CONSULTAR</TextBtn>
              </Btn>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </>
  );
};

export default Catalogue;
