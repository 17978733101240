import React from 'react';
import AppLayout from 'layout/AppLayout';
import {
  Home,
  Catalogue,
  CatalogueNew,
  DecoHome,
  DecoHomeProducts,
  Dealers,
  Install,
  Size,
  Login,
  Proximamente,
  Users,
  NotFound,
  Register,
  Products,
  Carousel,
  Faqs,
  ContactPage,
  Colors,
  Categories,
  CategoriesCatalogue,
  CategoriesCataloguex,
  CategoriesStandarProducts,
  StandarProducts,
  Materials,
  QuoterPage,
  StandardPurchasePage,
  Standar,
  Cart,
  CustomCurtains,
  QuotedCurtains,
} from './pages';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import BackOfficeLayout from 'layout/BackOfficeLayout';
import { SnackbarProvider } from 'notistack';

const App = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1500}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="success"
    >
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/loginX" element={<Login />} />
            <Route path="/" element={<Proximamente />} />
            <Route element={<AppLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/catalogo" element={<Catalogue />} />
              <Route path="/catalogonew" element={<CatalogueNew />} />

              <Route path="/decohome" element={<DecoHome />} />

              <Route
                path="/decohome/productos"
                element={<DecoHomeProducts />}
              />

              <Route path="/distribuidores" element={<Dealers />} />
              <Route path="/comomedir" element={<Size />} />
              <Route path="/comoinstalar" element={<Install />} />
              <Route path="/preguntasfrecuentes" element={<Faqs />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/quoter" element={<QuoterPage />} />
              <Route
                path="/standardPurchase"
                element={<StandardPurchasePage />}
              />
              <Route path="/standar" element={<Standar />} />
              <Route path="/cart" element={<Cart />} />
            </Route>
            <Route element={<BackOfficeLayout />}>
              <Route path="/products" element={<Products />} />
              <Route path="/images" element={<Carousel />} />
              <Route path="/users" element={<Users />} />
              <Route path="/colors" element={<Colors />} />
              <Route path="/categories" element={<Categories />} />
              <Route
                path="/categoriescatalogue"
                element={<CategoriesCatalogue />}
              />
              <Route
                path="/categoriescataloguex"
                element={<CategoriesCataloguex />}
              />
              <Route
                path="/categoriesstandarproducts"
                element={<CategoriesStandarProducts />}
              />

              <Route path="/standarproducts" element={<StandarProducts />} />

              <Route path="/materials" element={<Materials />} />
              <Route path="/customCurtains" element={<CustomCurtains />} />
              <Route path="/quotedCurtains" element={<QuotedCurtains />} />
            </Route>
            <Route path="/notFound" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthContextProvider>
    </SnackbarProvider>
  );
};

export default App;
