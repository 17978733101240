import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useQuery from 'hooks/useQuery';
import { CustomCurtainsService } from 'services';

const Option = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${color}`,
  color: 'black',
  minWidth: '100px',
  padding: '5px 15px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Filters = ({ formData, setFormData, parameters }) => {
  const query = useQuery();
  const [blackout, setBlackout] = useState(null);
  const [sunscreen, setSunscreen] = useState(null);

  useEffect(() => {
    if (query.get('category') === 'cortina_doble') {
      CustomCurtainsService.getBySlug('blackout').then((res) =>
        setBlackout(res),
      );
      CustomCurtainsService.getBySlug('sunscreen').then((res) =>
        setSunscreen(res),
      );
    }
  }, [query.get('category')]);

  useEffect(() => {
    setFormData({
      ...formData,
      color: null,
    });
  }, [formData.type]);

  const handleChangeSize = ({ target }) => {
    var value = parseInt(target.value, 10);
    if (value > 250 && target.name === 'width' && formData.height > 250)
      value = 250;
    if (value > 250 && target.name === 'height' && formData.width > 250)
      value = 250;
    if (value < 1) value = 1;
    setFormData({
      ...formData,
      [target.name]: value,
    });
  };

  const handleChangeOptions = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Grid item container spacing={2}>
      {query.get('category') === 'cortina_doble' ? (
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>1. Tipo de tela - Blackout</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {blackout?.types.map((type, idx) => (
                    <Grid item display="block" key={idx}>
                      <Option
                        color={
                          formData.type1?.description === type.description
                            ? '#012AFF'
                            : '#9A9A9A'
                        }
                        onClick={() => handleChangeOptions('type1', type)}
                      >
                        <Typography>{type.description}</Typography>
                      </Option>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>1. Tipo de tela - Sunscreen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {sunscreen?.types.map((type, idx) => (
                    <Grid item display="block" key={idx}>
                      <Option
                        color={
                          formData.type2?.description === type.description
                            ? '#012AFF'
                            : '#9A9A9A'
                        }
                        onClick={() => handleChangeOptions('type2', type)}
                      >
                        <Typography>{type.description}</Typography>
                      </Option>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>2. Color Blackout</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="space-around">
                  {formData.type1 &&
                    formData.type1.colors.map((color, idx) => (
                      <Grid
                        item
                        display="block"
                        key={idx}
                        px={2}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => handleChangeOptions('color1', color)}
                      >
                        <Typography>{color.name}</Typography>
                        <img
                          src={color.url}
                          height={45}
                          width={45}
                          style={{
                            borderRadius: '50%',
                            border:
                              color.name === formData.color1?.name &&
                              '1px solid #012AFF',
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>2. Color Sunscreen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="space-around">
                  {formData.type2 &&
                    formData.type2.colors.map((color, idx) => (
                      <Grid
                        item
                        display="block"
                        key={idx}
                        px={2}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => handleChangeOptions('color2', color)}
                      >
                        <Typography>{color.name}</Typography>
                        <img
                          src={color.url}
                          height={45}
                          width={45}
                          style={{
                            borderRadius: '50%',
                            border:
                              color.name === formData.color2?.name &&
                              '1px solid #012AFF',
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>1. Tipo de tela</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {parameters?.types.map((type, idx) => (
                    <Grid item display="block" key={idx}>
                      <Option
                        color={
                          formData.type?.description === type.description
                            ? '#012AFF'
                            : '#9A9A9A'
                        }
                        onClick={() => handleChangeOptions('type', type)}
                      >
                        <Typography>{type.description}</Typography>
                      </Option>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
              >
                <Typography>2. Color</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="space-around">
                  {formData.type &&
                    formData.type.colors.map((color, idx) => (
                      <Grid
                        item
                        display="block"
                        key={idx}
                        px={2}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => handleChangeOptions('color', color)}
                      >
                        <Typography>{color.name}</Typography>
                        <img
                          src={color.url}
                          height={45}
                          width={45}
                          style={{
                            borderRadius: '50%',
                            border:
                              color.name === formData.color?.name &&
                              '1px solid #012AFF',
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>3. Medidas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name="width"
                  label="Ancho (cm)"
                  type="number"
                  value={formData.width}
                  onChange={handleChangeSize}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item display="flex" alignItems="center">
                <Typography fontFamily="Inter">X</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="height"
                  label="Alto (cm)"
                  type="number"
                  value={formData.height}
                  onChange={handleChangeSize}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>4. Caída del rollo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {!parameters?.rollDropTypes.length && (
                <Typography mt={1}>Sin opciones</Typography>
              )}
              {parameters?.rollDropTypes.map((rollDrop) => (
                <Grid item key={rollDrop?.id}>
                  <Option
                    color={
                      formData.rollDrop?.id === rollDrop.id
                        ? '#012AFF'
                        : '#9A9A9A'
                    }
                    onClick={() => handleChangeOptions('rollDrop', rollDrop)}
                  >
                    <Typography>{rollDrop.description}</Typography>
                  </Option>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>5. Posición del mando</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {!parameters?.positions.length && (
                <Typography mt={1}>Sin opciones</Typography>
              )}
              {parameters?.positions.map((position) => (
                <Grid item key={position.id}>
                  <Option
                    color={
                      formData.position?.id === position.id
                        ? '#012AFF'
                        : '#9A9A9A'
                    }
                    onClick={() => handleChangeOptions('position', position)}
                  >
                    <Typography>{position.description}</Typography>
                  </Option>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>6. Tipo de cadena</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {!parameters?.chainTypes.length && (
                <Typography mt={1}>Sin opciones</Typography>
              )}
              {parameters?.chainTypes.map((chainType) => (
                <Grid item key={chainType.id}>
                  <Option
                    color={
                      formData.chainType?.id === chainType.id
                        ? '#012AFF'
                        : '#9A9A9A'
                    }
                    onClick={() => handleChangeOptions('chainType', chainType)}
                  >
                    <Typography>{chainType.description}</Typography>
                  </Option>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Filters;
