// @ts-nocheck
import React, { useState, useEffect } from 'react';

import { Grid, styled, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
//LoadingCircularProgress
import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDispatch, useSelector } from 'react-redux';

const TextTitle = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign: 'left',
  color: 'black',
  textTransform: 'none',
  fontSize: '25px',
}));

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 700,
  textAlign: props.align || 'center',
  color: 'black',
}));

const Cart = () => {
  const dispatch = useDispatch();
  const pedido = useSelector((state) => state.pedido);
  console.log('pedido:', pedido);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filterCatalogue, setFilterCatalogue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const [itemDelete, setItemDelete] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [textSuccessAlert, setTextSuccessAlert] = useState('');

  const [categorie, setCategorie] = useState('All');
  const [categoriesStandar, setCategoriesStandar] = useState([]);

  const [filterCategorieFromDialog, setFilterCategorieFromDialog] =
    useState('');

  useEffect(() => {
    console.log('Cart page');
  }, []);

  const returnTotalPedido = () => {
    let total = 0;
    pedido.map((row, index) => {
      total += row?.precioTotal;
    });
    return total;
  };

  const deleteItem = (row) => {
    dispatch({ type: 'DELETE_ITEM', payload: row });
    return;
  };

  return (
    <Grid container direction="row" mt={5}>
      <LoadingCircularProgress open={isLoading} />

      <Grid item xs={12} display={'flex'} flexDirection={'column'}>
        <TextTitle ml={5}>Carrito de Compras</TextTitle>

        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Grid item xs={11}>
            <TableContainer
              component={Paper}
              style={{ backgroundColor: '#185D22' }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography color={'white'}>Producto </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}>Precio </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}>Cantidad </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}>Total </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}>Eliminar </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedido.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">
                        <Typography color={'white'} fontSize={'16x'}>
                          {row?.name +
                            ' ' +
                            row?.color +
                            ' ' +
                            row?.base +
                            ' x ' +
                            row?.altura}{' '}
                        </Typography>
                        <Typography
                          color={'white'}
                          fontSize={'13px'}
                        ></Typography>{' '}
                        <Typography color={'white'} fontSize={'13px'}>
                          {'Caida del rollo: ' + row?.rollDrop}
                        </Typography>{' '}
                        <Typography color={'white'} fontSize={'13px'}>
                          {'Posición del mando: ' + row?.position}
                        </Typography>{' '}
                        <Typography color={'white'} fontSize={'13px'}>
                          {'Tipo cadena: ' + row?.chainType}
                        </Typography>{' '}
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={'white'}>{row?.price} </Typography>{' '}
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={'white'}>
                          {row?.quantity}{' '}
                        </Typography>{' '}
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={'white'}>
                          {row?.precioTotal}{' '}
                        </Typography>{' '}
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={'white'}>
                          <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteItem(row)}
                          />{' '}
                        </Typography>{' '}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow
                    key={'index'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}> Total pedido</Typography>{' '}
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'white'}>
                        {' '}
                        {returnTotalPedido()}
                      </Typography>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Grid
              item
              xs={12}
              mt={4}
              display={'flex'}
              justifyContent={'center'}
            >
              <Grid item xs={4} display={'flex'}>
                <Button
                  variant="contained"
                  fullWidth
                  color="success"
                  sx={{ height: 50, borderRadius: 10 }}
                >
                  <WhatsAppIcon style={{ marginRight: '5px' }} />
                  Solcitar via WhatsApp
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cart;
