import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  circularProgressClasses,
} from '@mui/material';

const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#D9D9D9' }}
        size={'100%'}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        size={'100%'}
        color="success"
        sx={{
          position: 'absolute',
          left: 1,
          top: -1,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
