import React, { useState, useEffect, createContext, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'firebaseApp';
import { getUser } from 'services/users';

const AuthContext = createContext(null);

export const AuthContextProvider = (props) => {
  const userData = localStorage.getItem('user');
  const [user, setUser] = useState(userData ? JSON.parse(userData) : null);

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, async (result) => {
      if (result && !user) {
        const dbUserData = await getUser(result.uid);
        const newUser = {
          id: result.uid,
          email: result.email,
          rol: dbUserData.rol,
        };
        setUser(newUser);
        localStorage.setItem('user', JSON.stringify(newUser));
      } else if (!result) {
        setUser(null);
        localStorage.removeItem('user');
      }
    });
    return () => unsuscribe();
  }, []);
  return <AuthContext.Provider value={{ user }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null };
};

export default AuthContext;
