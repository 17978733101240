import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';

const Container = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(1, '10rem'),
  minHeight: '800px',
  [theme.breakpoints.down('md')]: {
    // padding: theme.spacing(1, 1),
  },
}));

const AppLayout = () => {
  return (
    <Grid container direction="column">
      <Header />
      <Container item container>
        <Outlet />
      </Container>
      <Footer />
    </Grid>
  );
};

export default AppLayout;
