import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { blackout, doble, sunscreen } from 'assets/cortinas-a-medida';

// const Filters = () => {

const Option = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${color}`,
  color: 'black',
  minWidth: '100px',
  padding: '5px 15px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Filters = ({ formData, setFormData }) => {
  useEffect(() => {
    setFormData({
      ...formData,
      color: null,
    });
  }, [formData.type]);

  const handleChangeOptions = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>1. Caída del rollo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              <Grid item key={'POR DELANTE'}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.rollDrop === 'POR DELANTE' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('rollDrop', 'POR DELANTE')}
                >
                  <Typography fontSize={'14px'}>{'POR DELANTE'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'POR DETRÁS'}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.rollDrop === 'POR DETRÁS' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('rollDrop', 'POR DETRÁS')}
                >
                  <Typography fontSize={'14px'}>{'POR DETRÁS'}</Typography>
                </Option>
              </Grid>

              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Por delante
                </Button>
              </Grid> */}

              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                  onClick={() => handleChangeOptions('rollDrop', 'Por detrás')}
                >
                  Por detrás
                </Button>
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>2. Posición del mando</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Izquierda
                </Button>
              </Grid> */}
              <Grid item key={'IZQUIERDA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.position === 'IZQUIERDA' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('position', 'IZQUIERDA')}
                >
                  <Typography fontSize={'14px'}>{'IZQUIERDA'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'DERECHA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.position === 'DERECHA' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('position', 'DERECHA')}
                >
                  <Typography fontSize={'14px'}>{'DERECHA'}</Typography>
                </Option>
              </Grid>

              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Derecha
                </Button>
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#2C9B31', borderRadius: '10px' }}
          >
            <Typography>3. Tipo de cadena</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Plástica
                </Button>
              </Grid> */}
              <Grid item key={'PLÁSTICA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.chainType === 'PLÁSTICA' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('chainType', 'PLÁSTICA')}
                >
                  <Typography fontSize={'14px'}>{'PLÁSTICA'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'METÁLICA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.chainType === 'METÁLICA' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('chainType', 'METÁLICA')}
                >
                  <Typography fontSize={'14px'}>{'METÁLICA'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'MOTORIZADA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.chainType === 'MOTORIZADA' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('chainType', 'MOTORIZADA')}
                >
                  <Typography fontSize={'14px'}>{'MOTORIZADA'}</Typography>
                </Option>
              </Grid>

              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Metálica
                </Button>
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ bgcolor: '#D9D9D9', borderRadius: '10px' }}
          >
            <Typography>4. Colocación</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Si
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid> */}
    </Grid>
  );
};

export default Filters;
