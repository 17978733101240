import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import { ReactComponent as Logo } from 'assets/logo2.svg';
import { ReactComponent as Instagram } from 'assets/instagram.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, '10rem'),
  justifyContent: 'space-between',
  margin: 0,
  backgroundColor: '#E5E5E5',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 1),
  },
}));

// @ts-ignore
const Text = styled(Typography)(({ color, fontWeight }) => ({
  fontFamily: 'Rubik, sans-serif',
  fontSize: 16,
  fontWeight: fontWeight || 400,
  color: color || '#676767',
  // '&:hover': {
  //   fontWeight: 700,
  //   cursor: 'pointer',
  // },
}));

const GreenText = styled(Typography)(() => ({
  fontFamily: 'Rubik, sans-serif',
  marginLeft: 16,
  marginBottom: 16,
  fontWeight: 600,
  fontSize: 16,
  color: '#299A30',
  '&:hover': {
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Root container>
      <Grid item xs={3} container direction="column">
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <GreenText onClick={() => navigate('/comomedir')}>
            Cómo medir
          </GreenText>
          <GreenText onClick={() => navigate('/comoinstalar')}>
            Cómo instalar
          </GreenText>
          <GreenText onClick={() => navigate('/preguntasfrecuentes')}>
            Preguntas frecuentes
          </GreenText>
        </Grid>
      </Grid>
      <Grid xs={3} item marginTop={4}>
        <Text fontWeight={600}>Cortinas a medida</Text>
        <ul>
          <li>
            <Text>Bandas</Text>
          </li>
          <li>
            <Text>Blackout</Text>
          </li>
          <li>
            <Text>Sunscreen</Text>
          </li>
          <li>
            <Text>Toldos</Text>
          </li>
        </ul>
        <Text
          fontWeight={600}
          color={'#914DC7'}
          marginBottom={2}
          onClick={() => navigate('/decohome')}
        >
          Deco Home
        </Text>
        <Text
          fontWeight={600}
          marginBottom={2}
          onClick={() => navigate('/catalogo')}
        >
          Catálogo textil
        </Text>
        <Text fontWeight={600} onClick={() => navigate('/distribuidores')}>
          Distribuidores
        </Text>
      </Grid>
      <Grid xs={3} item marginTop={4}>
        <Text fontWeight={600}>Cortinas estandar</Text>
        <ul>
          <li>
            <Text>Bandas</Text>
          </li>
          <li>
            <Text>Blackout</Text>
          </li>
          <li>
            <Text>Sunscreen</Text>
          </li>
          <li>
            <Text>Toldos</Text>
          </li>
        </ul>

        <Grid item container spacing={2}>
          <Grid item>
            <IconButton>
              <Facebook />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <Instagram />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={3} item container direction="column" marginTop={4}>
        <Grid item>
          <Text fontWeight={600} marginBottom={2}>
            Contactanos
          </Text>
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <LocationOnOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs>
            <Text>Ricardo Pedroni 1779 esquina, Octavio Pinto, Córdoba</Text>
          </Grid>
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <PhoneOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs ml={'5px'}>
            <Text>0351 244-3260</Text>
          </Grid>
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <EmailOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs ml={'5px'}>
            <Text>ventas@texalia.com.ar</Text>
          </Grid>
        </Grid>
        <Grid item container marginBottom={2}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <LocationOnOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs>
            <Text>Bv Las Flores 209, Villa Giardino</Text>
          </Grid>
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <PhoneOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs ml={'5px'}>
            <Text> 3548591884</Text>
          </Grid>
        </Grid>
        <Grid item container marginBottom={2}>
          <Grid item xs={1}>
            <EmailOutlined sx={{ color: '#185D22' }} />
          </Grid>
          <Grid item xs ml={'5px'}>
            <Text> infotexalia@gmail.com</Text>
          </Grid>
        </Grid>
        {/* <Grid item container spacing={2}>
          <Grid item>
            <IconButton>
              <Facebook />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <Instagram />
            </IconButton>
          </Grid>
        </Grid> */}
      </Grid>
    </Root>
  );
};

export default Footer;
