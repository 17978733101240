import React, { useState, useEffect } from 'react';
import { Grid, styled, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const StandarProductsFilter = ({
  IntExt,
  NameSelected,
  setCategoriaDescripcion,
  catalogue,
  categoriesStandarProducts,
  setFilterCatalogue,
  filterCategorieFromDialog,
  setTextCategorie,
}) => {
  // console.log('StandarProductsFilter');

  const [filterBy, setFilterBy] = useState('all');

  useEffect(() => {
    filterProducts(filterBy);
  }, [catalogue]);

  // console.log('filterCategorieFromDialog:', filterCategorieFromDialog);

  useEffect(() => {
    // console.log('ACOMODAR :', categoriesStandarProducts);
    categoriesStandarProducts.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    // console.log('ACOMODAR 2:', categoriesStandarProducts);
  }, []);

  // useEffect(() => {
  //   if (filterCategorieFromDialog.length == 0) {
  //     if (categoriesCatalogue.length > 0) {
  //       setFilterBy(categoriesCatalogue[0].id);
  //       filterProducts(categoriesCatalogue[0].id);
  //       setText(categoriesCatalogue[0].id);
  //     }
  //   }
  // }, [categoriesCatalogue]);

  useEffect(() => {
    if (filterCategorieFromDialog.length > 0) {
      setFilterBy(filterCategorieFromDialog);
      filterProducts(filterCategorieFromDialog);
      // setText(filterCategorieFromDialog);
    }
  }, [filterCategorieFromDialog]);

  const handleChangeFilters = (event) => {
    event.preventDefault();

    setFilterBy(event.target.value);
    filterProducts(event.target.value);
  };

  const filterProducts = async (filterId) => {
    if (filterId == 'all') {
      setFilterCatalogue(catalogue);
      return;
    }
    if (catalogue.length > 0) {
      const result = catalogue.filter(
        (product) => product.categorie == filterId,
      );
      console.log('result:', result);

      setFilterCatalogue(result);
    }
  };

  // const setText = async (idCategorie) => {
  //   console.log('idCategorie:', idCategorie);

  //   if (idCategorie == 'ymiQAC35jf5SuvhfEfdT') {
  //     NameSelected('Blackout');
  //     IntExt('Interior');
  //     setCategoriaDescripcion(
  //       'Son telas de alto grado de opacidad. bloquean la entrada de luz desde el exterior, debido asu estructura sólida, además de bajar y regular la temperatura de los ambientes. Tela ignifuga, que permite su instalación en espacios publicos.',
  //     );
  //   }

  //   if (idCategorie == 'LQV0rjQVbVzjCT2yBp5a') {
  //     NameSelected('Sunscreen');
  //     IntExt('Interior');
  //     setCategoriaDescripcion(
  //       'El tejido SunScreen es el tejido que permite "ver sin ser visto". Muy resistente al paso del tiempo, de composición poliéster, pvc y fibra de vidrio, y dependiendo del modelo, de textura microperforada. Es gracias a sus orificios que deja pasar luz y aporta transparencia exterior.',
  //     );
  //   }

  //   if (idCategorie == 'vcVUVy9eRN11GO96zuBs') {
  //     NameSelected('Translúcidas');
  //     IntExt('Interior');
  //     setCategoriaDescripcion(
  //       'Permiten el paso de la luz en mayor o menor medida según el tejido y el gramaje del mismo. Esta es la diferencia fundamental con respecto alas opacas, que no permiten el paso de la luz brindando un aislamiento completo.',
  //     );
  //   }

  //   if (idCategorie == 'Q1tMndNDVTwS5mpIcATL') {
  //     NameSelected('Zebra');
  //     IntExt('Interior');
  //     setCategoriaDescripcion(
  //       'Tejido de doble capa compuesto por bandas horizontales transparentes y oscuras, que se activan mediante manivela o control remoto, creando la ilusión de dos persianas.',
  //     );
  //   }

  //   if (
  //     idCategorie == 'OkXhV7KIQLX4n4gCuNjP' ||
  //     idCategorie == 'PxxVzX2wdFnxlpwQM3jc' ||
  //     idCategorie == 'Ju8h1b19We38ZNSOU3ZO' ||
  //     idCategorie == 'DpS9NbNs3b5qsWlwvO7S' ||
  //     idCategorie == 'MKs3HCBUcIaPNnAt6aPx'
  //   ) {
  //     console.log('IF EXTERIOR');
  //     IntExt('Exterior');
  //     NameSelected('');
  //     setCategoriaDescripcion('');
  //   }
  // };

  const TextTitle = styled(Typography)((props) => ({
    position: 'relative',
    fontFamily: 'Inter',
    fontWeight: 300,
    textAlign: 'center',
    color: '#5B5B5B',
    textTransform: 'none',
    fontsize: '12px',
  }));

  return (
    // <Grid container className={classes.root2}>
    <Grid
      container
      // item

      style={{ backgroundColor: '#D9D9D9', padding: '5%', marginLeft: '5px' }}
    >
      {/* className={classes.rootNew} */}
      {/* <Grid
        item
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TextTitle fontSize={'22px'}>Filtrar</TextTitle>
      </Grid> */}

      <Grid
        item
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextTitle
          fontSize={'18px'}
          style={{ color: '#185D22', fontWeight: '300' }}
        >
          Filtrar
        </TextTitle>

        <KeyboardArrowDownIcon style={{ color: '#185D22' }} />
      </Grid>
      <Grid
        item
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Grid item lg={12} sm={12} xs={12}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              key={'all'}
              value={'all'}
              control={
                <Checkbox
                  checked={filterBy == 'all'}
                  name={'all'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Todos'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />
            {categoriesStandarProducts.length > 0 &&
              categoriesStandarProducts.map((categorie) => (
                <FormControlLabel
                  key={categorie.id}
                  // onClick={() => handleChangeFilters}
                  value={categorie.id}
                  control={
                    <Checkbox
                      checked={filterBy == categorie.id}
                      name={categorie.name}
                      onChange={handleChangeFilters}
                    />
                  }
                  label={
                    <Typography fontSize={'15px'} fontWeight={500}>
                      {categorie.name}
                    </Typography>
                  }
                  style={{
                    position: 'relative',
                    fontFamily: 'Inter',
                    fontWeight: 300,
                    textAlign: 'center',
                    color: '#5B5B5B',
                    textTransform: 'none',
                  }}
                />
              ))}
          </Box>

          {/* <Divider style={{ color: '#000000' }} /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
